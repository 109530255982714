import type { ExtensionConfig } from '@goto/shell-common'
import { getFeatureFlag } from '../helpers/shell-helpers'
import { UnleashFlags } from '../models/feature-flag.models'
import { getOOSArtifactUrl } from '../helpers/experience-helpers'
import { createIntegrationConfigIfFlagEnabled } from '../helpers/integration-helpers'

const integrationFlagSuffix = 'oos-meeting-meet-now'
const extensionConfig: ExtensionConfig = {
  id: '@goto/oos-meeting-meet-now',
  get url() {
    const version = getFeatureFlag(UnleashFlags.OOS_MEET_NOW_VERSION, 'release')
    return new URL(getOOSArtifactUrl('meeting-meet-now-extension', version))
  },
  get integrationConfig() {
    return createIntegrationConfigIfFlagEnabled(integrationFlagSuffix)
  },
}

export default extensionConfig
