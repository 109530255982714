import type { ExtensionConfig } from '@goto/shell-common'
import { getFeatureFlag } from '../helpers/shell-helpers'

const contactCenterConfigBaseUrl = '/experiences/cc-config'

const extensionConfig: ExtensionConfig = {
  id: '@ucc/cc-config/extension',
  get url() {
    // Specifying version is only used as exception for a quick rollback for instance, and not to manage deployement
    const configVersion = getFeatureFlag('cc-config-version', 'latest')
    return new URL(
      `${contactCenterConfigBaseUrl}/${configVersion}/extension.js`,
      location.origin,
    )
  },
  prerequisites: {
    ccFlags: 'cc-config.gtc || engagements',
  },
  optionalDependencies: [
    '@dce/engagements-web-components-loader-extension'
  ],
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
}

export default extensionConfig
