export interface FeatureFlagService {
  readonly context: string
  readonly feature: AvailableFeatureFlags
  readonly isDefault: boolean
  readonly setting: FeatureFlagSetting
}

export enum AvailableFeatureFlags {
  MEETINGS = 'jiveweb.meetings',
  STANDARD = 'jiveweb.standard',
  MESSAGING = 'jiveweb.messaging',
}

export enum FeatureFlagSetting {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum LaunchDarklyVariations {
  ENABLE_GTT_GOTO_APP = 'enable-gtt-goto-app',
  ENABLE_GTW_GOTO_APP = 'enable-gtw-goto-app',
  OOS_MEETING_NOTIFICATION = 'oos-meeting-notification',
  SHELL_ENABLE_MEETINGS_FOR_CONNECT_BUNDLE = 'shell-enable-meetings-for-connect-bundle',
  GTC_MOBILE_NEW_CONTACT_ARTEFACT = 'gtc-mobile-new-contact-artefacts',
  SHELL_SHOW_GOTO_MEETING_LINK = 'shell-show-goto-meeting-link',
  OOS_MEETING_APP_VERSION = 'oos-meeting-app-version',
  OOS_MEET_NOW_VERSION = 'oos-meeting-meetnow-version',
  OOS_MEETING_BG_SERVICE_VERSION = 'oos-meeting-bg-service-version',
  OOS_TRAINING_VERSION = 'oos-training-app-version',
  OOS_WEBINAR_VERSION = 'oos-webinar-app-version',
  CONTACTS_VERSIONS = 'contacts-team-app-versions',
}

export interface LaunchDarklyPayload {
  readonly [LaunchDarklyVariations.ENABLE_GTT_GOTO_APP]?: boolean
  readonly [LaunchDarklyVariations.ENABLE_GTW_GOTO_APP]?: boolean
  readonly [LaunchDarklyVariations.OOS_MEETING_NOTIFICATION]?: boolean
  readonly [LaunchDarklyVariations.SHELL_ENABLE_MEETINGS_FOR_CONNECT_BUNDLE]?: boolean
  readonly [LaunchDarklyVariations.GTC_MOBILE_NEW_CONTACT_ARTEFACT]?: string
  readonly [LaunchDarklyVariations.SHELL_SHOW_GOTO_MEETING_LINK]?: string
  readonly [LaunchDarklyVariations.OOS_MEETING_APP_VERSION]?: string
  readonly [LaunchDarklyVariations.OOS_MEET_NOW_VERSION]?: string
  readonly [LaunchDarklyVariations.OOS_MEETING_BG_SERVICE_VERSION]?: string
  readonly [LaunchDarklyVariations.OOS_TRAINING_VERSION]?: string
  readonly [LaunchDarklyVariations.OOS_WEBINAR_VERSION]?: string
  readonly [LaunchDarklyVariations.CONTACTS_VERSIONS]?: { extension: string; experience: string }
}

export enum UnleashFlags {
  OOS_MEETING_APP_VERSION = 'oos-meeting-app-version',
  OOS_MEET_NOW_VERSION = 'oos-meeting-meetnow-version',
  OOS_MEETING_BG_SERVICE_VERSION = 'oos-meeting-bg-service-version',
  OOS_TRAINING_VERSION = 'oos-training-app-version',
  OOS_WEBINAR_VERSION = 'oos-webinar-app-version',
  OOS_WEBINAR_V2_VERSION = 'oos-webinar-app-v2-version',
  OOS_TEASER_VERSION = 'oos-teaser-app-version',
}
