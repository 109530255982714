import { loadScript } from '@goto/shell'
import { getFeatureFlag } from './shell-helpers'

export const getContactCenterAgentBaseUrl = () => {
  const agentVersion = getFeatureFlag('cc-agent-version', 'latest')
  return `/experiences/cc-agent/${agentVersion}`
}

// TODO https://jira.ops.expertcity.com/browse/CCE-2785 
// fix target version since FF is null at this monment and always returns default value 'latest'
// so using the hardcoded version for now
export const loadContactCenterAgentService = async () => {
  try {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window as any)['@ucc/cc-agent/service']) {
    return
  }
    // please dont use this url elsewhere, use getContactCenterAgentBaseUrl() instead
    await loadScript(`/experiences/cc-agent/latest/service.js`, {
      resolveWithGlobal: '@ucc/cc-agent/service',
    });
  } catch (err) {
    console.error('[GoTo-App]: Error loading cc-agent service', err)
  }
}

export const isLocalhost = () => location.hostname === 'localhost' || location.hostname === '127.0.0.1'