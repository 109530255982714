import type { ExtensionConfig } from '@goto/shell-common';
import { getContactCenterAgentBaseUrl } from '../helpers/contact-center-agent-experience-helpers';

export const extensionConfig: ExtensionConfig = {
  id: '@ucc/cc-agent/extension',
  get url() {
    // Specifying version is only used as exception for a quick rollback for instance, and not to manage deployement
    return new URL(
       `${getContactCenterAgentBaseUrl()}/extension.js` ,
      location.origin,
    )
  },
  prerequisites: {
    ccFlags: 'cc-agent.portal',
  },
  integrationConfig: {
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
  },
}

export default extensionConfig
